import Page from '#/components/shared/ui/Page';
import AGBContent from '#/components/pages/TCPP/AGB';

const PrivacyPolicy = () => (
  <Page title="AGB">
    <AGBContent />
  </Page>
);

export default PrivacyPolicy;
